import { useEffect } from 'react';

const YandexMetrika = () => {
  useEffect(() => {
    if (window.ym) return; // Если Yandex.Metrika уже инициализирована, не повторяем загрузку

    // Загружаем скрипт Yandex.Metrika
    const script = document.createElement('script');
    script.async = true;
    script.src = "https://mc.yandex.ru/metrika/tag.js";
    
    script.onload = () => {
      // Проверяем, что скрипт загружен и инициализируем счетчик
      if (window.ym) {
        window.ym(99217052, "init", {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
        });
      }
    };

    script.onerror = (err) => {
      console.error("Ошибка загрузки Yandex.Metrika:", err);
    };

    document.head.appendChild(script);

    return () => {
      // Очистка скрипта при размонтировании компонента
      document.head.removeChild(script);
    };
  }, []);

  return null; // Этот компонент ничего не рендерит
};

export default YandexMetrika;

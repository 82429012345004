import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navigation.css';

const Navigation = () => {
  return (
    <div className="navigation">
      <NavLink to="/search" activeClassName="active">
        {/* SVG вместо текста */}
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512">
          <path d="M256 0c-141.385 0-256 114.615-256 256s114.615 256 256 256 256-114.615 256-256-114.615-256-256-256zM48 256c0-114.875 93.125-208 208-208 54.787 0 104.616 21.193 141.767 55.814l-205.767 88.186-88.186 205.767c-34.621-37.151-55.814-86.98-55.814-141.767zM292.583 292.583l-128.041 54.875 54.875-128.041 73.166 73.166zM256 464c-54.787 0-104.617-21.193-141.766-55.814l205.766-88.186 88.186-205.766c34.621 37.149 55.814 86.979 55.814 141.766 0 114.875-93.125 208-208 208z"></path>
        </svg>
      </NavLink>
      <NavLink to="/journey" activeClassName="active">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32"  viewBox="0 0 512 512">
        <g id="icomoon-ignore">
        </g>
        <path d="M192 32h320v64h-320v-64zM192 224h320v64h-320v-64zM192 416h320v64h-320v-64zM0 64c0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64s-64-28.654-64-64zM0 256c0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64s-64-28.654-64-64zM0 448c0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64s-64-28.654-64-64z"></path>
        </svg>
      </NavLink>
      <NavLink to="/friends" activeClassName="active">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg"width="32" height="32" viewBox="0 0 576 512">
        <g id="icomoon-ignore">
        </g>
        <path d="M384 385.306v-26.39c35.249-19.864 64-69.386 64-118.916 0-79.529 0-144-96-144s-96 64.471-96 144c0 49.53 28.751 99.052 64 118.916v26.39c-108.551 8.874-192 62.21-192 126.694h448c0-64.484-83.449-117.82-192-126.694z"></path>
        <path d="M163.598 397.664c27.655-18.075 62.040-31.818 99.894-40.207-7.527-8.892-14.354-18.811-20.246-29.51-15.207-27.617-23.246-58.029-23.246-87.947 0-43.021 0-83.655 15.3-116.881 14.853-32.252 41.564-52.248 79.611-59.744-8.457-38.24-30.97-63.375-90.911-63.375-96 0-96 64.471-96 144 0 49.53 28.751 99.052 64 118.916v26.39c-108.551 8.874-192 62.21-192 126.694h139.503c7.259-6.455 15.298-12.586 24.095-18.336z"></path>
        </svg>
      </NavLink>
      <NavLink to="/profile" activeClassName="active">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512">
        <g id="icomoon-ignore">
        </g>
        <path d="M288 353.306v-26.39c35.249-19.864 64-69.386 64-118.916 0-79.529 0-144-96-144s-96 64.471-96 144c0 49.53 28.751 99.052 64 118.916v26.39c-108.551 8.874-192 62.21-192 126.694h448c0-64.484-83.449-117.82-192-126.694z"></path>
        </svg>
      </NavLink>
    </div>
  );
};

export default Navigation;
